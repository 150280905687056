ptz-toast {
  .ptz-toast {
    position: fixed;
    pointer-events: none;
    z-index: 1;
    background: $petz-color-neutral-darker-accent;
    padding: $petz-spacing-xs $petz-spacing-sm;
    border-radius: $petz-border-lg;
    box-shadow: $petz-shadow-sm;
    max-width: 327px;
    width: 100%;
    visibility: hidden;

    &-open {
      visibility: visible !important;
    }

    &-span {
      color: $petz-color-neutral-white;
      font-size: $petz-font-size-2xs;
    }

    &-bottom-right {
      bottom: $petz-font-size-2xl;
      right: $petz-font-size-2xl;
    }

    &-bottom-left {
      bottom: $petz-font-size-2xl;
      left: $petz-font-size-2xl;
      right: $petz-font-size-2xl;
    }

    &-bottom-center {
      bottom: $petz-font-size-2xl;
      left: calc(50% - 178px);
      right: 50%;
    }

    &-top-right {
      right: $petz-font-size-2xl;
    }

    &-top-left {
      left: $petz-font-size-2xl;
      right: $petz-font-size-2xl;
    }

    &-top-center {
      left: calc(50% - 178px);
      right: 50%;
    }
  }

  .srs-toast {
    position: fixed;
    z-index: 1;
    pointer-events: none;
    background: $seres-color-neutral-darker-accent;
    padding: $seres-spacing-xs $seres-spacing-sm;
    border-radius: $seres-border-lg;
    box-shadow: $seres-shadow-sm;
    max-width: 327px;
    width: 100%;
    visibility: hidden;

    &-open {
      visibility: visible !important;
    }

    &-span {
      color: $seres-color-neutral-white;
      font-size: $seres-font-size-2xs;
    }

    &-bottom-right {
      bottom: $seres-font-size-2xl;
      right: $seres-font-size-2xl;
    }

    &-bottom-left {
      bottom: $seres-font-size-2xl;
      left: $seres-font-size-2xl;
      right: $seres-font-size-2xl;
    }

    &-bottom-center {
      bottom: $seres-font-size-2xl;
      left: calc(50% - 178px);
      right: 50%;
    }

    &-top-right {
      right: $seres-font-size-2xl;
    }

    &-top-left {
      left: $seres-font-size-2xl;
      right: $seres-font-size-2xl;
    }

    &-top-center {
      left: calc(50% - 178px);
      right: 50%;
    }
  }
}

ptz-skeleton {
  position: fixed;
  z-index: 1;
  pointer-events: none;

  &-bottom-right {
    bottom: $seres-font-size-2xl;
    right: $seres-font-size-2xl;
  }

  &-bottom-left {
    bottom: $seres-font-size-2xl;
    left: $seres-font-size-2xl;
    right: $seres-font-size-2xl;
  }

  &-bottom-center {
    bottom: $seres-font-size-2xl;
    left: calc(50% - 178px);
    right: 50%;
  }

  &-top-right {
    right: $seres-font-size-2xl;
  }

  &-top-left {
    left: $seres-font-size-2xl;
    right: $seres-font-size-2xl;
  }

  &-top-center {
    left: calc(50% - 178px);
    right: 50%;
  }
}
