import { EnumToArray } from '../../../utils/enumToArray';
import { PTZBrandConfig, PTZBrandConfigList } from '../../ptz-brand/types/ptz-brand.enums';

enum Align {
  BottomLeft = 'bottom-left',
  BottomRight = 'bottom-right',
  BottomCenter = 'bottom-center',
  TopLeft = 'top-left',
  TopRight = 'top-right',
  TopCenter = 'top-center',
}

export const PTZToastConfigList = {
  Align: EnumToArray([Align]),
  Brand: PTZBrandConfigList.Name,
};

export const PTZToastConfig = {
  Align,
  Brand: PTZBrandConfig.Name,
};
