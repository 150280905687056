import { Component, Host, Prop, h } from '@stencil/core';
import { valueToLowerCaseForDataTestId } from '../../utils/utils';
import { PTZBrandConfig } from '../ptz-brand/types/ptz-brand.enums';
import { PTZBrandTypes } from '../ptz-brand/types/ptz-brand.types';
import { PTZToastConfig } from './types/ptz-toast.enums';
import { PTZToastTypes } from './types/ptz-toast.types';

@Component({
  tag: 'ptz-toast',
  styleUrl: 'ptz-toast.scss',
  shadow: false,
})
export class PTZToast {
  /** Se verdadeiro, o componente é exibido. */
  @Prop() open: boolean;

  /** Atributo define a label do toast */
  @Prop() label: string;

  /** Atributo define a posição */
  @Prop() align?: PTZToastTypes.Align = PTZToastConfig.Align.BottomLeft;

  /** Atributo define a marca */
  @Prop() brand?: PTZBrandTypes.Name = PTZBrandConfig.Name.Petz;

  /** Estado de renderização */
  @Prop() skeleton: boolean = false;

  private getDurationTime() {
    const labelLength = this.label.length;
    const DEFAULT_DURATION = 160;
    return DEFAULT_DURATION * labelLength;
  }

  private timeout = null;

  componentDidRender() {
    this.timeout = setTimeout(() => {
      this.open = false;
    }, this.getDurationTime());
  }

  disconnectedCallback() {
    clearTimeout(this.timeout);
  }

  render() {
    return (
      <Host>
        {this.skeleton ? (
          <ptz-skeleton class={`${this.align ? `${this.brand}-toast-${this.align}` : ''}`} width={359} height={40} rounded="xl" />
        ) : (
          <div
            class={`
            ${this.brand}-toast
            ${this.open ? `${this.brand}-toast-open` : ''}
            ${this.align ? `${this.brand}-toast-${this.align}` : ''}`}
          >
            <span data-testid={`${this.brand}-${valueToLowerCaseForDataTestId(this.label)}toast`} role="toast" class={`${this.brand}-toast-span`}>
              {this.label}
            </span>
          </div>
        )}
      </Host>
    );
  }
}
